import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
	selector: '[appTypography]',
})
export class TypographyDirective {
	@Input() textStyle:
		| 'huge'
		| 'title'
		| 'header-1'
		| 'header-2'
		| 'subtitle'
		| 'body-large'
		| 'body-regular'
		| 'body-small'
		| 'button'
		| 'text-button'
		| 'tooltip'
		| undefined;

	constructor() {}

	@HostBinding('class') get classes(): string {
		return `typography ${this.textStyle}`;
	}
}
