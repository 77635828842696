import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapsLockDirective } from './caps/caps-lock.directive';
import { TypographyDirective } from './typography/typography.directive';

@NgModule({
	declarations: [CapsLockDirective, TypographyDirective],
	imports: [CommonModule],
	exports: [CapsLockDirective, TypographyDirective],
})
export class UiModule {}
